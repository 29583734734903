/* eslint-disable eqeqeq */
<template>
  <CrmFormLayout :title="isCustomer ? '合同详情' : '查看合同'">
    <el-form :model="formData" ref="form" label-position="left" label-width="100px" :show-message="true">
      <div class="mali-edit__form">
        <el-row :gutter="80">
          <el-col :span="12">
            <el-form-item label="合同编号" prop="contractNo">
              <el-input
                v-model="formData.contractNo"
                :maxlength="30"
                clearable
                placeholder="请输入合同编号"
                :disabled="readOnly"
                class="mali-full__input"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="客&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;户" prop="customerName">
              <el-select
                v-model="formData.customerName"
                class="mali-full__input"
                clearable
                filterable
                remote
                :disabled="readOnly || formType > 1"
                :remote-method="getCustomerOption"
                @change="getCustomerId"
                :class="{ 'edit-no': formType === 2 }"
                placeholder="请选择客户"
              >
                <el-option v-for="item in customerOption" :key="item.id" :label="item.customerName" :value="item.id"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="80">
          <el-col :span="12">
            <el-form-item label="签约主体" prop="principalName">
              <el-select
                v-model="formData.principalName"
                class="mali-full__input"
                clearable
                filterable
                :disabled="readOnly || formType > 1"
                :class="{ 'edit-no': formType === 2, 'no-select-arrow': readOnly }"
                placeholder="请选择签约主体"
              >
                <el-option
                  v-for="item in principalNameOption"
                  :key="item.contractPrincipalId"
                  :label="item.principalName"
                  :value="item.contractPrincipalId"
                ></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="签约时间">
              <el-date-picker
                v-if="formData.signDate || !readOnly"
                v-model="formData.signDate"
                type="date"
                prefix-icon="null"
                placeholder="请选择签约时间"
                :disabled="readOnly"
                :editable="false"
                class="mali-full__input"
              ></el-date-picker>
              <el-input v-model="sapcedata" :maxlength="50" v-else :disabled="readOnly" class="mali-full__input"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="80">
          <el-col :span="12">
            <el-form-item label="业务类型" prop="businessType">
              <el-select
                :disabled="true"
                :class="{
                  'edit-no': formType === 2 || formType === 1,
                  'no-select-arrow': readOnly,
                }"
                v-model="formData.businessType"
                clearable
                filterable
                class="mali-full__input"
                placeholder="请选择业务类型"
              >
                <el-option :label="item.label" :value="item.value" v-for="(item, index) in filterOption.contract_business_type" :key="index"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="计费方式" prop="settleTypeDesc">
              <el-input v-model="settleFeeDesc" :maxlength="30" clearable placeholder="请选择计费方式" :disabled="readOnly" class="mali-full__input"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="80">
          <el-col :span="12">
            <el-form-item label="生效时间" prop="startDate">
              <el-date-picker
                v-model="formData.startDate"
                v-if="formData.startDate || !readOnly"
                type="date"
                prefix-icon="null"
                placeholder="请选择生效时间"
                :disabled="readOnly"
                :editable="false"
                class="mali-full__input"
              ></el-date-picker>
              <el-input v-model="sapcedata" :maxlength="50" v-else :disabled="readOnly" class="mali-full__input"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="到期时间" prop="endDate">
              <el-date-picker
                v-model="formData.endDate"
                v-if="!readOnly || formData.endDate"
                type="date"
                prefix-icon="null"
                placeholder="请选择到期时间"
                :disabled="readOnly"
                :editable="false"
                class="mali-full__input"
              ></el-date-picker>
              <el-input v-model="sapcedata" :maxlength="50" v-else :disabled="readOnly" class="mali-full__input"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
      </div>
      <div class="mali-edit__title" v-if="fileUrlsArray">合同文件</div>
      <div class="mali-edit__form" style="padding: 20px 20px" v-if="fileUrlsArray">
        <FileContent :readOnly="readOnly" :fromType="formType" fileType="1" :max="1" :fileList="fileUrlsArray"></FileContent>
      </div>
      <div class="maili-edit__form label-con" style="margin-top: 20px" v-if="formType < 3 || (formType == 3 && formData.remark)">
        <el-form-item label="合同备注" prop="remark">
          <el-input
            v-if="formData.remark || !readOnly"
            v-model="formData.remark"
            :maxlength="300"
            :disabled="readOnly"
            class="mali-full__input h60"
            placeholder="请输入合同备注"
            type="textarea"
            resize="none"
          ></el-input>
          <el-input v-else v-model="sapcedata" :disabled="readOnly" class="mali-full__input"></el-input>
        </el-form-item>
      </div>

      <div v-if="isDayFee && isShowCharge">
        <div class="dayFee-tips">
          <img src="../../../../../assets/images/erp/contract-upload-tips.png" alt />
          <span class="dayfee-text">计费标准 (按天计费)</span>
        </div>
        <div class="mali-edit__title" style="margin-top: 20px">半期货服务费</div>
        <div class="mali-edit__form">
          <el-row :gutter="80">
            <el-col :span="12">
              <el-form-item label="服务费(元/柜)" prop="halfQhdcServiceFee">
                <el-input :model-value="filterName(formData.halfQhdcServiceFeeDisplay)" disabled></el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </div>

        <div class="mali-edit__title" style="margin-top: 20px">代理费</div>
        <table class="contract-table-custom">
          <tr>
            <th align="center" width="90">大类</th>
            <th width="290">大柜代理费</th>
            <th width="290">大柜最低吨数</th>
            <th width="290">小柜代理费</th>
          </tr>
          <tr v-for="(item, index) in formData.agencyFeeList" :key="index + 'clearance'">
            <td align="center">{{ item.categoryDesc }}</td>
            <td align="center">{{ item.clearanceFeeLarge }} {{ item.clearanceFeeLargeUnitDesc }}</td>
            <td align="center">{{ filterName(item.clearanceFeeLargeMinWeight) }}</td>
            <td align="center">
              {{ item.clearanceFeeSmall }}
              {{ item.clearanceFeeSmallUnitDesc ? item.clearanceFeeSmallUnitDesc : '元/柜' }}
            </td>
          </tr>
        </table>
        <div class="mali-edit__title" style="margin-top: 20px">代采费</div>
        <table class="contract-table-custom">
          <tr>
            <th align="center" width="50%">大类</th>
            <th align="center" width="50%">代采费</th>
          </tr>
          <tr v-for="(item, index) in formData.cgFeeList" :key="index + 'cgFee'">
            <td align="center">{{ item.categoryDesc }}</td>
            <td align="center">{{ item.agencyFee }} {{ item.agencyFeeUnitDesc }}</td>
          </tr>
        </table>

        <div class="mali-edit__title" style="margin-top: 20px">保险费</div>
        <div class="mali-edit__form">
          <el-row :gutter="80">
            <el-col :span="12">
              <el-form-item label="保险费率(‰)">
                <el-input
                  v-model="formData.insuranceRate"
                  :maxlength="4"
                  clearable
                  placeholder="请输入保险费率(‰)"
                  :disabled="readOnly"
                  class="mali-full__input"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </div>
        <div class="mali-edit__title" style="margin-top: 20px">多品名费用</div>
        <table class="contract-table-custom">
          <tr>
            <th style="width: 229px">收费标准</th>
            <th style="width: 229px">最小值</th>
            <th style="width: 229px">最大值</th>
            <th style="width: 229px">费用(元)</th>
          </tr>
          <tr v-for="(item, index) in formData.multiProductFeeConfigList" :key="index + 'labelfee'">
            <td style="width: 229px">{{ item.feeTypeDesc }}</td>
            <td style="width: 229px">{{ item.configMin }}</td>
            <td style="width: 229px">{{ item.configMax }}</td>
            <td style="width: 229px">{{ item.fee }}</td>
          </tr>
        </table>
        <div class="mali-edit__title" style="margin-top: 20px">多品类费用</div>
        <div class="mali-edit__form">
          <el-row :gutter="80">
            <el-col :span="12">
              <el-form-item label="开始收费的品种数" prop="multiVarietyFeeStartCount">
                <el-input
                  v-model="formData.multiVarietyFeeStartCount"
                  :maxlength="2"
                  clearable
                  placeholder="请输入开始收费的品种数"
                  :disabled="readOnly"
                  class="mali-full__input"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="费用(元/品种)" prop="multiVarietyFee">
                <el-input
                  v-model="formData.multiVarietyFee"
                  :maxlength="4"
                  clearable
                  placeholder="请输入费用(元/品种)"
                  :disabled="readOnly"
                  class="mali-full__input"
                ></el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </div>
        <div class="mali-edit__title" style="margin-top: 20px">多次提货费</div>

        <table class="contract-table-custom">
          <tr>
            <th style="width: 306px">最小值</th>
            <th style="width: 305px">最大值</th>
            <th style="width: 305px">费用(元/次)</th>
          </tr>
          <tr v-for="(item, index) in formData.multiPickupFeeConfigList" :key="index + 'deliveryfee'">
            <td style="width: 306px">{{ item.configMin }}</td>
            <td style="width: 305px">{{ item.configMax }}</td>
            <td style="width: 305px">{{ item.fee }}</td>
          </tr>
        </table>

        <div class="mali-edit__title" style="margin-top: 20px">利率</div>
        <table class="contract-table-custom">
          <tr>
            <th style="width: 230px">最小天数</th>
            <th style="width: 228px">最大天数</th>
            <th style="width: 228px">利率 (年化%)</th>
            <th style="width: 230px">最小使用天数</th>
          </tr>
          <tr v-for="(item, index) in formData.interestRateConfigList" :key="index + 'ratefee'">
            <td style="width: 230px">{{ item.configMin }}</td>
            <td style="width: 228px">{{ item.configMax }}</td>
            <td style="width: 228px">{{ item.rate }}</td>
            <td style="width: 230px">{{ item.minUseDays }}</td>
          </tr>
        </table>
        <div class="mali-edit__title" style="margin-top: 20px">其他费用 (按天计费)</div>
        <div class="contract-table-box" style="margin-top: 20px">
          <table class="contract-table-custom">
            <tr>
              <th width="50%">费用名称</th>
              <th width="50%">收费标准</th>
            </tr>
            <tbody v-if="formData.dayOtherFeeList && formData.dayOtherFeeList.length > 0">
              <tr v-for="(item, index) in formData.dayOtherFeeList" :key="index + 'otherFeeByDay'">
                <!-- <td>{{ filterName(item.feeName) }}</td> -->
                <td>
                  <span class="show-item" style="line-height: 30px">
                    操作费
                    <span class="feeNameTip">(垫资的情况下才收取此费用)</span>
                  </span>
                </td>
                <td>{{ filterName(item.feeStandard) }}{{ item.feeUnitDesc }}</td>
              </tr>
            </tbody>
            <tbody v-else>
              <tr>
                <td>
                  <span class="show-item">-</span>
                </td>
                <td>
                  <span class="show-item">-</span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div v-if="isBagFee && isShowCharge">
        <div class="dayFee-tips">
          <img src="../../../../../assets/images/erp/contract-upload-tips.png" alt />
          <span class="dayfee-text">计费标准 (包干价)</span>
        </div>
        <div class="mali-edit__title">其他费用 (包干价)</div>
        <table class="contract-table-custom">
          <tr>
            <th width="50%">费用名称</th>
            <th width="50%">收费标准</th>
          </tr>
          <tbody v-if="formData.bagOtherFeeList && formData.bagOtherFeeList.length > 0">
            <tr v-for="(item, index) in formData.bagOtherFeeList" :key="index + 'otherFeeBaoGan'">
              <!-- <td>{{ filterName(item.feeName) }}</td> -->
              <td>其他服务费</td>
              <td>{{ filterName(item.feeStandard) }}{{ item.feeUnitDesc }}</td>
            </tr>
          </tbody>
          <tbody v-else>
            <tr>
              <td>
                <span class="show-item">-</span>
              </td>
              <td>
                <span class="show-item">-</span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </el-form>
    <div class="crm-button__group" v-if="formType >= 3">
      <button class="crm-form__button primary" @click="close(false)">关闭</button>
    </div>
  </CrmFormLayout>
</template>

<script>
import FileContent from '@/components/FileContent/FileContent.vue';
import { store } from '@/store';
export default {
  components: { FileContent },
  props: {
    formType: {
      // 表单操作类型 1 新增 2 修改 3查看
      type: [String, Number],
      default: 1,
    },
    id: {
      type: [String, Number],
      default: '',
    },
    isCustomer: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      sapcedata: '-',
      customerOption: null,

      formData: {
        settleType: [1],
        settleTypeDesc: '按天计费',
        contractNo: null,
        signDate: null,
        customerId: null,
        contractName: null,
        businessType: 1,
        startDate: null,
        endDate: null,
        attachment: {
          customerBaseFileId: null,
          customerBaseFileName: null,
          customerBaseFileUrl: null,
        },
        remark: null,
        clearanceFeeLarge: null, // 大柜代理费
        clearanceFeeLargeUnit: 1, // 大柜代理费单位
        clearanceFeeLargeMinWeight: 24, // 大柜最低吨数
        clearanceFeeSmall: null, // 小柜代理费（元/柜）
        agencyFee: null, // 代采费
        agencyFeeUnit: 1, // 代采费单位
        insuranceRate: null, // 保险费,
        multiProductFeeConfigList: [
          { configMin: 1, configMax: 2, fee: 0 },
          { configMin: 3, configMax: 7, fee: 500 },
          { configMin: 8, configMax: 10, fee: 1000 },
          { configMin: 11, configMax: 20, fee: 1500 },
          { configMin: 21, configMax: null, fee: 1600 },
        ],
        multiPickupFeeConfigList: [
          { configMin: 1, configMax: 5, fee: 0 },
          { configMin: 6, configMax: 10, fee: 200 },
          { configMin: 11, configMax: null, fee: 500 },
        ],
        interestRateConfigList: [
          { configMin: 1, configMax: 45, rate: null, minUseDays: 15 },
          { configMin: 46, configMax: 90, rate: null, minUseDays: null },
          { configMin: 91, configMax: null, rate: null, minUseDays: null },
        ],
        agencyFeeList: [],
        cgFeeList: [],
        dayOtherFeeList: [],
        bagOtherFeeList: [],
        multiVarietyFeeStartCount: 2, // 开始收费的品种数
        multiVarietyFee: 300, // 费用（元/品种）
        halfQhdcServiceFee: null,
      },
      filterOption: {
        contract_business_type: store.state.dict.options.contract_business_type,

        agency_fee_unit: store.state.dict.options.agency_fee_unit, // 代理费 元/吨

        clearance_fee_unit: store.state.dict.options.clearance_fee_unit, // 大柜代理费单位元/吨
      },
      fileUrlsArray: [],
      fileNameArr: [],

      isLoadingAjax: false,
    };
  },
  created() {
    // 初始化客户筛选
    this.getCustomerOption('');
    if (this.formType > 1) {
      this.getinitData(this.id);
    }
  },
  computed: {
    readOnly() {
      return this.formType > 2;
    },
    isShowCharge() {
      if (this.formData.businessType === 1) {
        return true;
      } else {
        return false;
      }
    },
    isDayFee() {
      if (this.formData.isDay) {
        return true;
      } else {
        return false;
      }
    },
    isBagFee() {
      return this.formData.isBag;
    },
    settleFeeDesc() {
      let res = '';
      if (this.formData.isDay && !this.formData.isBag) {
        res = '按天计费';
      }
      if (!this.formData.isDay && this.formData.isBag) {
        res = '包干价';
      }
      if (this.formData.isDay && this.formData.isBag) {
        res = '按天计费、包干价';
      }
      return res;
    },
    clearanceFeeLargeUnit() {
      return this.formData.clearanceFeeLargeUnitDisplay;
    },
    agencyFeeUnit() {
      return this.formData.agencyFeeUnitDisplay;
    },
  },
  methods: {
    close() {
      this.$emit('close', false);
    },
    getinitData(id) {
      this.ajax({
        type: 'GET',
        url: '/malicrm/customer/getCustomerContractById/' + id,
        success: (res) => {
          if (res.code === 200) {
            console.log(res);
            this.formData = Object.assign({}, this.formData, res.result);
            console.log(this.formData);
            this.initUrl();
          } else {
            this.errorTip(res.message);
          }
        },
      });
    },
    initUrl() {
      if (this.formData.attachments) {
        this.fileUrlsArray = [];
        this.formData.attachments.forEach((item) => {
          if (item.customerBaseFileName && item.customerBaseFileUrl) {
            this.fileUrlsArray.push({
              name: item.customerBaseFileName,
              url: item.customerBaseFileUrl,
            });
          }
        });
      }
    },

    cancelSubmit() {
      this.$emit('cancel', false);
    },

    /*
     * 获取客户筛选条件option
     * params@name 用户输入值
     */
    getCustomerOption(name) {
      const url = '/malicrm/business/selectCustomer';
      const data = { customerName: name };
      this.ajax({
        type: 'POST',
        url,
        data,
        success: (res) => {
          if (res.code === 200) {
            this.customerOption = [];
            this.customerOption = res.result.map((item) => {
              return { customerName: item.customerName, id: item.id };
            });
          }
        },
      });
    },

    /*
     * 获取客户 id
     */
    getCustomerId(val) {
      this.customerOption.forEach((item) => {
        if (item.id === val) {
          this.formData.customerName = item.customerName;
          this.formData.customerId = item.id;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped></style>
